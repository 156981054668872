import { InfoWindow, Marker } from '@react-google-maps/api';
import React, { ReactElement, useCallback, useRef, useState } from 'react';

interface MarkerWithInfoProps {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  info: ReactElement;
}

const MarkerWithInfo: React.FC<MarkerWithInfoProps> = ({ position, info }) => {
  const marker = useRef<Marker | null>(null);

  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const handleMarkerClick = useCallback(() => setInfoWindowShown((isShown) => !isShown), []);
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <>
      <Marker ref={marker} position={position} onClick={handleMarkerClick} />

      {infoWindowShown && (
        <InfoWindow anchor={marker.current?.marker} onCloseClick={handleClose}>
          {info}
        </InfoWindow>
      )}
    </>
  );
};

export default MarkerWithInfo;
