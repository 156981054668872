import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { WCU } from '@services/wcu';
import { getProductsWithVersions, Product } from '@services/product';
import { updateVersions, UpdateCommandArguments } from '@services/update';
import { Grid, MenuItem, Select, SelectChangeEvent, Switch, Typography } from '@mui/material';
import { ActionsContainer, LoaderContainer } from './styles';
import useApi from '@hooks/useApi';
import CircularProgress from '@mui/material/CircularProgress';
import AlertDialog from '@components/AlertDialog';
import PaperComponent from '@components/Paper';

interface WCUPropsDialogProps {
  open: boolean;
  onCloseRequest: () => void;
  wcu: WCU[];
}

const SELECT_MIN_WIDTH = 180;

export default function UpdateVersionsDialog(props: WCUPropsDialogProps): ReactElement {
  const { open, onCloseRequest, wcu } = props;
  const [updates, setUpdates] = useState<UpdateCommandArguments[]>([]);
  const [isForced, setIsForced] = useState<boolean>(false);
  const products = useApi(getProductsWithVersions);
  const update = useApi(updateVersions);
  const [message, setMessage] = useState<string | ReactElement>('');

  const setDefaults = (): void => {
    setUpdates([]);
    setIsForced(false);
  };

  useEffect(() => {
    if (!open) {
      return setDefaults();
    }
    void products.request();
  }, [open]);

  useEffect(() => {
    if (!update.data) {
      return;
    }
    setMessage(<Typography>{update.data.detail}</Typography>);
  }, [update.data]);

  useEffect(() => {
    if (!update.error) {
      return;
    }
    const msg = <Typography>{update.error}</Typography>;
    setMessage(msg);
  }, [update.error]);

  const onVersionSelect = (productId: string, version: string): void => {
    setUpdates((state) => {
      const index = state.findIndex((u) => u.product === productId);
      if (version === '') {
        if (index !== -1) {
          return state.slice(0, index).concat(updates.slice(index + 1));
        }
        return state;
      }
      if (index === -1) {
        return state.concat([
          {
            product: productId,
            version
          }
        ]);
      }
      const newUpdates: UpdateCommandArguments[] = [...updates];
      newUpdates[index] = {
        product: productId,
        version
      };
      return newUpdates;
    });
  };

  const onApply = (): void => {
    void update.request(
      updates,
      wcu.map((wcu) => wcu.wcu_id),
      isForced
    );
    setDefaults();
  };

  const applyEnabled = !update.loading && updates.length > 0;
  const loadingIsActive = products.loading || update.loading;

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Update Versions
      </DialogTitle>
      <DialogContent>
        {loadingIsActive && (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        )}
        <Grid container>
          {!loadingIsActive && (
            <Fragment>
              {products.data?.map((product: Product) => (
                <Fragment key={product.id}>
                  <Grid item sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{product.name}:</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Select
                      size="small"
                      sx={{ m: 1, minWidth: SELECT_MIN_WIDTH }}
                      defaultValue=""
                      onChange={(event: SelectChangeEvent) => {
                        onVersionSelect(product.id, event.target.value);
                      }}>
                      <MenuItem value="">
                        <em>none</em>
                      </MenuItem>
                      {product.versions?.map((version: string) => (
                        <MenuItem key={version} value={version}>
                          {version}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Fragment>
              ))}
              <Grid item sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Immediate Update:</Typography>
              </Grid>
              <Grid item sm={6}>
                <Switch
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsForced(event.target.checked);
                  }}
                  value={isForced}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
        <AlertDialog
          title="Update Result"
          content={message}
          open={message !== ''}
          onCloseRequest={() => {
            setMessage('');
          }}
        />
      </DialogContent>
      <ActionsContainer>
        <Button sx={{ float: 'left' }} onClick={onCloseRequest} disabled={update.loading}>
          Close
        </Button>
        <Button
          disabled={!applyEnabled}
          sx={{ float: 'right' }}
          variant="contained"
          onClick={onApply}>
          Apply
        </Button>
      </ActionsContainer>
    </Dialog>
  );
}
