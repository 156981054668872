import styled from 'styled-components';

export const BoatsContainerWidth = '450px';

export const PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const BoatsContainer = styled.div`
  position: relative;
  height: 100%;
  width: ${BoatsContainerWidth};
  box-sizing: border-box;
  padding: 10px 0;
`;

export const MapContainer = styled.div`
  height: 100%;
  flex-grow: 1;
`;

export const BoatsLoaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(50, 50, 50, 0.1);
`;

export const BoatsScrollContainer = styled.div`
  height: calc(100% - 75px);
  margin-top: 10px;
`;

export const BoatsScrollContent = styled.div`
  padding: 5px 10px;
`;

export const Boat = styled.div<{ $selected?: boolean }>`
  position: relative;
  box-shadow: 1px 1px 5px 1px rgba(50, 50, 50, 0.4);
  margin-bottom: 15px;
  border-radius: 3px;
  padding: 5px 45px 5px 10px;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  transition-property: box-shadow, background-color;
  background-color: ${(props) => (props.$selected ? 'rgba(100,100,100,0.2)' : '#fff')};
  width: calc(${BoatsContainerWidth} - 30px);
  box-sizing: border-box;

  &:hover {
    box-shadow: 3px 3px 5px 1px rgba(50, 50, 50, 0.4);
  }
`;
