import resolve from '../resolver';
import authorizedFetch from '../authorizedFetch';
import { WCUInfo } from '@services/wcu';

export interface HeartbeatInfo {
  all_services_working: boolean;
  services_down_list: string[];
  latitude: number;
  longitude: number;
  country: string;
}

export interface Heartbeat extends HeartbeatInfo {
  wcu_id: number;
  wcu_info: WCUInfo;
}

export interface HeartbeatFilters {
  descending?: boolean;
  sort_by?: keyof Heartbeat;
  country?: string;
  ids?: number[];
}

const DEFAULT_HEARTBEAT_FILTERS: HeartbeatFilters = {
  descending: true,
  sort_by: 'wcu_id'
};

function filtersToUrlParams(filters: HeartbeatFilters): URLSearchParams {
  const urlParams = new URLSearchParams();
  if (filters.descending) {
    urlParams.set('descending', filters.descending.toString());
  }
  if (filters.sort_by) {
    urlParams.set('sort_by', filters.sort_by);
  }
  if (filters.country) {
    urlParams.set('country', filters.country);
  }
  if (filters.ids) {
    for (const id of filters.ids) {
      urlParams.append('wcu_ids', id.toString());
    }
  }
  return urlParams;
}

export const list = async (
  filters: HeartbeatFilters = DEFAULT_HEARTBEAT_FILTERS
): Promise<Heartbeat[]> => {
  const urlParams = filtersToUrlParams(filters);
  const res = await authorizedFetch(resolve('heartbeat/', urlParams));
  return await res.json();
};
