import Paper, { PaperProps } from '@mui/material/Paper';
import { ReactElement } from 'react';
import Draggable from 'react-draggable';
import * as React from 'react';

export default function PaperComponent(props: PaperProps): ReactElement {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
