import React, { KeyboardEvent, ChangeEvent } from 'react';
import { PageContainer } from './styles';
import {
  Alert,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '@auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { MAIN } from '@constants/pages';
import CloseIcon from '@mui/icons-material/Close';

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const navigate = useNavigate();

  const { onLogin } = React.useContext(AuthContext);

  const handleClickShowPassword = (): void => setShowPassword((show): boolean => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  const onLoginClick = async (): Promise<void> => {
    if (!user || !password) {
      return setShowErrors(true);
    }
    const loggedIn = await onLogin(user, password);
    if (loggedIn) {
      return navigate(MAIN);
    }
    setErrorMessage('Wrong login or password');
  };

  return (
    <PageContainer>
      <Stack spacing={2}>
        <Collapse in={errorMessage !== ''}>
          <Alert
            variant="outlined"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage('');
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>
        <TextField
          label="Login"
          value={user}
          required
          error={showErrors && !user}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setUser(event.target.value);
          }}></TextField>
        <FormControl variant="outlined" required error={showErrors && !password}>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
              if (event.key === 'Enter') {
                event.preventDefault();
                void onLoginClick();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            void onLoginClick();
          }}>
          Login
        </Button>
      </Stack>
    </PageContainer>
  );
};

export default Login;
