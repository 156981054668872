import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { WCU } from '@services/wcu';
import { list, TideData } from '@services/tideData';
import {
  ActionsContainer,
  BusyContainer,
  ContentContainer,
  FlagsContainer,
  LoaderContainer,
  MainTidesContainer,
  TideContainer,
  TidesContainer,
  MoveBackIcon,
  MoveIcon,
  RegionContainer
} from './styles';
import useApi from '@hooks/useApi';
import CircularProgress from '@mui/material/CircularProgress';
import PaperComponent from '@components/Paper';
import { Switch, Typography } from '@mui/material';
import { Scrollbar } from 'react-scrollbars-custom';
import { checkTasks, create } from '@services/platformTides';
import AlertDialog from '@components/AlertDialog';
import { RUNNING } from '@constants/taskStatus';

interface AssociatePropsDialogProps {
  open: boolean;
  onCloseRequest: () => void;
  wcu: WCU[];
}

function tidesToDict(tides: TideData[]): { [region: string]: TideData[] } {
  const dict: { [region: string]: TideData[] } = {};
  const copy = [...tides];
  copy.sort((a, b) => {
    const res = a.region.toLowerCase().localeCompare(b.region.toLowerCase());
    return res;
  });
  for (const tide of copy) {
    if (!dict[tide.region]) {
      dict[tide.region] = [];
    }
    dict[tide.region].push(tide);
  }
  return dict;
}

function moveTides(
  tides: TideData[],
  source: TideData[],
  target: TideData[]
): {
  source: TideData[];
  target: TideData[];
} {
  const sourceCopy: TideData[] = [...source];
  const targetCopy: TideData[] = [...target];
  for (const tide of tides) {
    targetCopy.push(tide);
    const idx = sourceCopy.findIndex((t) => t.region === tide.region);
    if (idx !== -1) {
      sourceCopy.splice(idx, 1);
    }
  }
  return {
    source: sourceCopy,
    target: targetCopy
  };
}

const TASKS_CHECK_TIMEOUT = 10000;

export default function UpdateVersionsDialog(props: AssociatePropsDialogProps): ReactElement {
  const { open, onCloseRequest, wcu } = props;
  const [isForced, setIsForced] = useState<boolean>(false);
  const [availableTides, setAvailableTides] = useState<TideData[]>([]);
  const [associateTides, setAssociateTides] = useState<TideData[]>([]);
  const [busyWCUs, setBusyWCUs] = useState<WCU[] | null>(null);
  const tides = useApi(list);
  const showResultsRef = useRef<boolean>(false);
  const [message, setMessage] = useState<string | ReactElement>('');
  const runningTasks = useApi(checkTasks);
  const createTasks = useApi(create);
  const tasksTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const setDefaults = (): void => {
    setIsForced(false);
    setBusyWCUs(null);
    setAssociateTides([]);
    setAvailableTides(tides.data ? [...tides.data] : []);
  };

  const wcuTide = useMemo(() => {
    const tide: { [id: number]: WCU } = {};
    if (wcu) {
      for (const unit of wcu) {
        tide[unit.wcu_id] = unit;
      }
    }
    return tide;
  }, [wcu]);

  useEffect(() => {
    if (runningTasks.data === null || !wcu) {
      return;
    }
    const busy = [];
    for (const id in runningTasks.data) {
      const parsedId = parseInt(id);
      if (
        (runningTasks.data[id] === RUNNING || createTasks.loading) &&
        wcuTide[parsedId] !== undefined
      ) {
        busy.push(wcuTide[parsedId]);
      }
    }
    busy.sort((a, b) => a.wcu_id - b.wcu_id);
    setBusyWCUs(busy);
    if (busy.length === 0 && showResultsRef.current) {
      showResultsRef.current = false;
      const msg = <Fragment>{getWCUsStatus(wcu)}</Fragment>;
      setMessage(msg);
    }
  }, [runningTasks.data, createTasks.loading]);

  useEffect(() => {
    if (!tides.data) {
      return;
    }
    setAvailableTides(tides.data);
    setAssociateTides([]);
  }, [tides.data]);

  useEffect(() => {
    if (!tides.error) {
      return;
    }
    const msg = <Typography>{tides.error}</Typography>;
    setMessage(msg);
  }, [tides.error]);

  const availableTidesElements = useMemo(() => {
    const res = [];
    const dict = tidesToDict(availableTides);
    for (const region in dict) {
      const regionElement = (
        <RegionContainer key={region}>
          {dict[region].map((t) => (
            <TideContainer key={t.tide_id}>
              <Typography>
                {t.region} - {t.vendor}
              </Typography>
              <MoveIcon
                onClick={() => {
                  const res = moveTides([t], availableTides, associateTides);
                  setAssociateTides(res.target);
                  setAvailableTides(res.source);
                }}
              />
            </TideContainer>
          ))}
        </RegionContainer>
      );
      res.push(regionElement);
    }
    return res;
  }, [availableTides]);

  const associateTidesElements = useMemo(() => {
    const res = [];
    const dict = tidesToDict(associateTides);
    for (const region in dict) {
      const regionElement = (
        <RegionContainer key={region}>
          {dict[region].map((t) => (
            <TideContainer key={t.tide_id}>
              <Typography>
                {t.region} - {t.vendor}
              </Typography>
              <MoveBackIcon
                onClick={() => {
                  const res = moveTides([t], associateTides, availableTides);
                  setAssociateTides(res.source);
                  setAvailableTides(res.target);
                }}
              />
            </TideContainer>
          ))}
        </RegionContainer>
      );
      res.push(regionElement);
    }
    return res;
  }, [associateTides]);

  const getWCUsStatus = (wcus: WCU[]): ReactElement[] => {
    return wcus.map((u) => {
      const message = `${u.wcu_id}(${u.boat_name || 'no name'})`;
      const status = runningTasks?.data?.[String(u.wcu_id)];
      return (
        <Typography key={u.wcu_id}>
          {message}
          {status ? ' - ' + status : ''}
        </Typography>
      );
    });
  };

  const onApply = (): void => {
    const wcuIds = wcu.map((u) => u.wcu_id);
    const tideIds = associateTides.map((t) => t.tide_id);
    void createTasks.request(wcuIds, tideIds, isForced).then(async () => {
      showResultsRef.current = true;
      initCheckTasksStatusInterval();
    });
    initCheckTasksStatusInterval();
  };

  const loadingIsActive = tides.loading;
  const showBusy = busyWCUs !== null && busyWCUs.length > 0;
  const applyEnabled =
    busyWCUs !== null &&
    !showBusy &&
    !loadingIsActive &&
    associateTides.length > 0 &&
    !createTasks.loading;

  const checkTasksStatus = (): void => {
    void runningTasks.request(wcu.map((u) => u.wcu_id));
  };

  const initCheckTasksStatusInterval = (): void => {
    clearTimeout(tasksTimeoutRef.current);
    checkTasksStatus();
    tasksTimeoutRef.current = setInterval(checkTasksStatus, TASKS_CHECK_TIMEOUT);
  };

  useEffect(() => {
    return () => {
      clearTimeout(tasksTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      clearTimeout(tasksTimeoutRef.current);
      return setDefaults();
    }
    initCheckTasksStatusInterval();
    void tides.request();
  }, [open]);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Associate Tides
      </DialogTitle>
      <DialogContent>
        <ContentContainer>
          {loadingIsActive && (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          )}
          <MainTidesContainer>
            <TidesContainer>
              <Scrollbar>{availableTidesElements}</Scrollbar>
            </TidesContainer>
            <TidesContainer>
              <Scrollbar>{associateTidesElements}</Scrollbar>
            </TidesContainer>
          </MainTidesContainer>
          <FlagsContainer>
            <Typography>Immediate Update:</Typography>
            <Switch
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIsForced(event.target.checked);
              }}
              value={isForced}
            />
          </FlagsContainer>
          {showBusy && (
            <BusyContainer>
              <CircularProgress />
              <Typography variant="h6">WCU tide association task in progress</Typography>
            </BusyContainer>
          )}
        </ContentContainer>
        <AlertDialog
          title="Associate Result"
          content={message}
          open={message !== ''}
          onCloseRequest={() => {
            setMessage('');
          }}
        />
      </DialogContent>
      <ActionsContainer>
        <Button sx={{ float: 'left' }} onClick={onCloseRequest}>
          Close
        </Button>
        <Button
          disabled={!applyEnabled}
          sx={{ float: 'right' }}
          variant="contained"
          onClick={onApply}>
          Apply
        </Button>
      </ActionsContainer>
    </Dialog>
  );
}
