import resolve from '../resolver';
import authorizedFetch from '../authorizedFetch';

export interface MapData {
  vendor: string;
  region: string;
  country: string;
  map_id: number;
  last_upload_time: string;
}

export interface MapFilters {
  descending?: boolean;
  sort_by?: keyof MapData;
}

const DEFAULT_MAP_FILTERS: MapFilters = {
  descending: true,
  sort_by: 'map_id'
};

function filtersToUrlParams(filters: MapFilters): URLSearchParams {
  const urlParams = new URLSearchParams();
  if (filters.descending) {
    urlParams.set('descending', filters.descending.toString());
  }
  if (filters.sort_by) {
    urlParams.set('sort_by', filters.sort_by);
  }
  return urlParams;
}

export const list = async (filters: MapFilters = DEFAULT_MAP_FILTERS): Promise<MapData[]> => {
  const urlParams = filtersToUrlParams(filters);
  const res = await authorizedFetch(resolve('maps/', urlParams));
  return await res.json();
};
