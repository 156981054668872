import styled from 'styled-components';

export const PageContainer = styled.div`
  height: 100%;
`;

export const TableContainer = styled.div`
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;
