// import { useAppSelector } from '@redux/hooks';
// import { selectColorPalette, ColorPalette } from '@redux/wuVesselInfo';

export interface Theme {
  text: string;
  fontFamily: string;
}

const DayTheme: Theme = {
  text: `#ffffff`,
  fontFamily: `'Roboto', sans-serif`
};

export const useTheme = (): Theme => {
  return DayTheme;
};
