import React, { ReactElement } from 'react';
import { BulletPoint } from './styles';

interface OnlineProps {
  isOnline: boolean;
}

export default function Online(props: OnlineProps): ReactElement {
  const { isOnline } = props;
  return <BulletPoint isOnline={isOnline} />;
}
