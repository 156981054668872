import React, { Fragment, ReactElement } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { alpha, TextField, Typography } from '@mui/material';
import { ActionsContainer, FiltersContainer } from '@components/Table/Toolbar/styles';

interface EnhancedTableToolbarProps {
  title: string;
  onSearch?: (value: string) => void;
  numSelected?: number;
  actions?: ReactElement[];
  filters?: ReactElement[];
}

export default function EnhancedTableToolbar(props: EnhancedTableToolbarProps): ReactElement {
  const { title, onSearch, numSelected, actions, filters } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!onSearch) {
      return;
    }
    onSearch(e.target.value);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(!!numSelected && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}>
      {numSelected ? (
        <Fragment>
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
            {actions && <ActionsContainer>{actions}</ActionsContainer>}
          </Typography>
        </Fragment>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
          {title}
        </Typography>
      )}
      <FiltersContainer>
        {filters}
        <TextField
          defaultValue=""
          onChange={onChange}
          label="Search field"
          variant="outlined"
          size="small"
        />
      </FiltersContainer>
    </Toolbar>
  );
}
