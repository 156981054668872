const TOKEN_STORAGE_KEY = 'T';

export const get = (): string | null => {
  return sessionStorage.getItem(TOKEN_STORAGE_KEY);
};

export const set = (token: string): void => {
  sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
};

export const remove = (): void => {
  sessionStorage.removeItem(TOKEN_STORAGE_KEY);
};
