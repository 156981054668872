import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MAIN, LOGIN, SEA_MAP } from '@constants/pages';
import Main from '@pages/Main';
import Login from '@pages/Login';
import { AuthProvider } from '@auth/AuthProvider';
import ProtectedRoute from './ProtectedRoute';
import SeaMap from '@pages/SeaMap';

interface RouteType {
  path: string;
  protected: boolean;
  element: React.ReactElement;
}

const navigation: RouteType[] = [
  {
    path: LOGIN,
    protected: false,
    element: <Login />
  },
  {
    path: MAIN,
    protected: true,
    element: <Main />
  },
  {
    path: SEA_MAP,
    protected: true,
    element: <SeaMap />
  }
];

const Router: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {navigation.map((item) => {
            return item.protected ? (
              <Route
                path={item.path}
                element={<ProtectedRoute>{item.element}</ProtectedRoute>}
                key={item.path}
              />
            ) : (
              <Route path={item.path} element={item.element} key={item.path} />
            );
          })}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Router;
