import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import { LOGIN } from '@constants/pages';

interface Props {
  children: ReactElement;
}

const ProtectedRoute = (props: Props): ReactElement => {
  const { token } = useAuth();

  if (!token) {
    return <Navigate to={LOGIN} replace />;
  }

  return props.children;
};

export default ProtectedRoute;
