import resolve from '../resolver';
import authorizedFetch from '../authorizedFetch';
import { TideData } from '@services/tideData';

const WCU_ID_PARAM_NAME = 'wcu_ids';

interface CheckTasksResponse {
  [id: string]: string;
}

interface WCUTide {
  association_time: string;
  tide_info: TideData;
}

export const getWCUTides = async (id: number): Promise<WCUTide[]> => {
  const res = await authorizedFetch(resolve(`platform-tides/${id}`));
  return await res.json();
};

export const create = async (
  wcuIds: number[],
  tideIds: number[],
  force: boolean = false
): Promise<void> => {
  const request = {
    wcu_ids: wcuIds,
    tide_ids: tideIds,
    create_commands: true,
    commands_force: force
  };

  const res = await authorizedFetch(resolve('platform-tides/'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });
  return await res.json();
};

export const checkTasks = async (ids: number[]): Promise<CheckTasksResponse> => {
  const urlParams = new URLSearchParams();
  ids.forEach((id) => urlParams.append(WCU_ID_PARAM_NAME, String(id)));
  const res = await authorizedFetch(resolve('platform-tides/check-tasks', urlParams));
  return await res.json();
};
