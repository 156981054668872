import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Checkbox } from '@mui/material';

type Order = 'asc' | 'desc';

export interface HeadCell<T> {
  id: string;
  field?: keyof T;
  label: string;
  numeric: boolean;
  getValue?: (item: T) => any;
  getPresentation?: (item: T) => string | ReactElement;
}

interface EnhancedTableHeadProps<T> {
  onRequestSort: (property: HeadCell<T>) => void;
  order: Order;
  orderBy: HeadCell<T>;
  headCells: Array<HeadCell<T>>;
  hasActions: boolean;
  numChecked: number;
  multiselect?: boolean;
  rowCount: number;
  onSelectAllClick?: (checked: boolean) => void;
}

export default function EnhancedTableHead<T>(props: EnhancedTableHeadProps<T>): ReactElement {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    hasActions,
    numChecked,
    multiselect,
    rowCount,
    onSelectAllClick
  } = props;
  const createSortHandler = (head: HeadCell<T>) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(head);
  };

  const selectAllClickHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onSelectAllClick) {
      onSelectAllClick(event.target.checked);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {multiselect && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numChecked > 0 && numChecked < rowCount}
              checked={rowCount > 0 && numChecked === rowCount}
              onChange={selectAllClickHandler}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.label}
            align="left"
            padding="normal"
            sortDirection={orderBy.id === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy.id === headCell.id}
              direction={orderBy.id === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell)}>
              {headCell.label}
              {orderBy.id === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {hasActions && <TableCell align="left" padding="normal"></TableCell>}
      </TableRow>
    </TableHead>
  );
}
