import resolve from '../resolver';
import authorizedFetch from '../authorizedFetch';

export interface UpdateCommandArguments {
  product: string;
  version: string;
}

interface UpdateCommand {
  command: 'update';
  arguments: UpdateCommandArguments;
}

interface CommandRequest {
  commands_obj: {
    commands: UpdateCommand[];
    force: boolean;
    restart: boolean;
  };
  wcus_list: number[];
}

export interface CommandResponse {
  status_code: number;
  detail: string;
}

export const updateVersions = async (
  args: UpdateCommandArguments[],
  wcuIds: number[],
  force: boolean = false
): Promise<CommandResponse> => {
  const request: CommandRequest = {
    commands_obj: {
      commands: args.map(
        (arg): UpdateCommand => ({
          command: 'update',
          arguments: arg
        })
      ),
      force,
      restart: true
    },
    wcus_list: wcuIds
  };

  const res = await authorizedFetch(resolve('ota/put-commands-in-s3'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });
  return await res.json();
};
