import resolve from '../resolver';

interface AuthResponse {
  access_token: string;
  token_type: string;
}

export const getToken = async (username: string, password: string): Promise<string | null> => {
  const form = new URLSearchParams();
  form.append('username', username);
  form.append('password', password);

  try {
    const res = await fetch(resolve('login'), {
      method: 'post',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: form.toString()
    });

    const json: AuthResponse = await res.json();
    return json.access_token ?? null;
  } catch (e: unknown) {
    return null;
  }
};
