import { useState } from 'react';

interface UseApiResult<T> {
  data: T | null;
  error: string;
  loading: boolean;
  request: (...args: any[]) => Promise<void>;
}

const EMPTY_ERROR_VALUE = '';

function useApi<T>(apiFunc: (...args: any[]) => Promise<T | null>): UseApiResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(EMPTY_ERROR_VALUE);
  const [loading, setLoading] = useState(false);

  const request = async (...args: any[]): Promise<void> => {
    setLoading(true);
    try {
      const result = await apiFunc(...args);
      setData(result);
      setError(EMPTY_ERROR_VALUE);
    } catch (err) {
      setError('Unexpected Error!');
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request
  };
}

export default useApi;
