import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactElement } from 'react';

interface AlertPropsDialogProps {
  title: string;
  content: string | ReactElement;
  open: boolean;
  onCloseRequest: () => void;
}

export default function AlertDialog(props: AlertPropsDialogProps): ReactElement {
  const { title, content, open, onCloseRequest } = props;

  return (
    <Dialog
      open={open}
      onClose={onCloseRequest}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onCloseRequest} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
