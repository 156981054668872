import * as process from 'process';
const protocol = process.env.REACT_APP_BACKEND_PROTOCOL ?? 'http';
const host = process.env.REACT_APP_BACKEND_HOST ?? location.hostname;
const port =
  process.env.REACT_APP_BACKEND_PORT === ''
    ? ''
    : ':' + (process.env.REACT_APP_BACKEND_PORT ?? '8000');
const prefix = process.env.REACT_APP_BACKEND_API_PREFIX ?? '';
const backendURL = `${protocol}://${host}${port}${prefix}`;

const resolve = (relativeUrl: string, urlParams?: URLSearchParams): string => {
  relativeUrl = relativeUrl[0] === '/' ? relativeUrl : '/' + relativeUrl;
  let url = backendURL + relativeUrl;
  if (urlParams) {
    url += '?' + urlParams.toString();
  }
  console.log('Resolver called with:', { relativeUrl, finalUrl: url });
  return url;
};

export default resolve;
