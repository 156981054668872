import { get as getToken } from '@helpers/tokenStorage';

export interface MyRequestInit extends Omit<RequestInit, 'headers'> {
  headers?: Record<string, string>;
}

const authorizedFetch = async (url: string, options: MyRequestInit = {}): Promise<Response> => {
  const token = getToken() ?? '';
  if (!options.headers) {
    options.headers = {};
  }
  options.headers.Authorization = 'Bearer ' + token;
  return await fetch(url, options);
};

export default authorizedFetch;
