import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactElement, useEffect } from 'react';
import { WCU } from '@services/wcu';
import { Divider, Grid, Typography } from '@mui/material';
import PaperComponent from '@components/Paper';
import useApi from '@hooks/useApi';
import { getWCUMaps } from '@services/platformMaps';
import { getWCUTides } from '@services/platformTides';

interface WCUPropsDialogProps {
  open: boolean;
  onCloseRequest: () => void;
  wcu: WCU | null;
}

export default function WCUPropsDialog(props: WCUPropsDialogProps): ReactElement {
  const { open, onCloseRequest, wcu } = props;
  const wcuMaps = useApi(getWCUMaps);
  const wcuTides = useApi(getWCUTides);

  useEffect(() => {
    if (!open || !wcu) {
      return;
    }
    void wcuMaps.request(wcu?.wcu_id);
    void wcuTides.request(wcu?.wcu_id);
  }, [open, wcu]);

  return (
    <Dialog open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {wcu?.boat_name}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">Versions</Typography>
        <Grid container>
          <Grid item sm={6}>
            <Typography>Argus:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.versions?.argus_repo}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>Web UI:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.versions?.web_ui}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>Carmenta Deployment:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.versions?.carmenta_deployment}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>Windows App:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.versions?.windows_app}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>Marinas DB:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.versions?.marinas_db}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '10px 0' }} />
        <Typography variant="h6">Licenses</Typography>
        <Grid container>
          <Grid item sm={6}>
            <Typography>WCU Serial:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.serials?.wcu_serial}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>Carmenta License ID:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.serials?.carmenta_license_id}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>Chartworld ID:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.serials?.chartworld_id}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>SIM ICCID:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>{wcu?.serials?.sim_iccid}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '10px 0' }} />
        <Typography variant="h6">Associated maps</Typography>
        <Typography>
          {wcuMaps.data?.map((m) => m.map_info.country + ' - ' + m.map_info.vendor).join(', ')}
          {wcuMaps.error || ''}
        </Typography>
        <Divider sx={{ margin: '10px 0' }} />
        <Typography variant="h6">Associated tides</Typography>
        <Typography>
          {wcuTides.data?.map((t) => t.tide_info.region + ' - ' + t.tide_info.vendor).join(', ')}
          {wcuTides.error || ''}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseRequest}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
