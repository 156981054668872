import React, { ReactElement } from 'react';
import { Tooltip } from '@mui/material';

export interface TableAction<T> {
  title: string;
  icon: ReactElement;
  action: (object: T) => void;
}

interface TableActionProps<T> {
  title: string;
  icon: ReactElement;
  action: (object: T) => void;
  object: T;
}

export default function EnhancedTableAction<T>(props: TableActionProps<T>): ReactElement {
  const { object, title, icon, action } = props;

  return (
    <Tooltip title={title}>
      {React.cloneElement(icon, {
        onClick: (): void => {
          action(object);
        }
      })}
    </Tooltip>
  );
}
