import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';

export const ContentContainer = styled.div`
  position: relative;
`;

export const ActionsContainer = styled.div`
  padding: 20px;
`;

export const LoaderContainer = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainMapsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 600px;
`;

export const MapsContainer = styled.div`
  flex: 1;
  min-height: 400px;
  border: 1px solid #aaa;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const RegionContainer = styled.div`
  padding: 10px;
  &:not(:first-child) {
    margin-top: 15px;
  }
`;

export const RegionName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(100, 100, 100, 0.15);
  padding: 5px;
  border-radius: 5px;
`;

export const RegionInnerContainer = styled.div`
  padding-left: 20px;
`;

export const MapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(100, 100, 100, 0.1);
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
`;

export const FlagsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

export const MoveIcon = styled(ArrowForwardIcon)`
  cursor: pointer;
`;

export const MoveBackIcon = styled(CloseIcon)`
  cursor: pointer;
`;

export const BusyContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`;

export const BusyBoats = styled.div`
  flex: 1;
  width: 100%;
  text-align: center;
`;
