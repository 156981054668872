export function descendingComparator<T>(a: T, b: T, getValue: (item: T) => any): number {
  if (getValue(b) < getValue(a)) {
    return -1;
  }
  if (getValue(b) > getValue(a)) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<T>(order: Order, getValue: (item: T) => any): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator<T>(a, b, getValue)
    : (a, b) => -descendingComparator<T>(a, b, getValue);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
