import styled from 'styled-components';

export const ActionsContainer = styled.span`
  margin-left: 20px;
  display: inline-flex;
  gap: 20px;
`;

export const FiltersContainer = styled.span`
  display: inline-flex;
  gap: 20px;
`;
