import React, { ReactElement, ReactNode } from 'react';
import jwtDdecode from 'jwt-decode';
import { getToken } from '@services/auth';
import { set, get, remove } from '@helpers/tokenStorage';

export interface AuthContextValue {
  token: string | null;
  onLogin: (user: string, pass: string) => Promise<boolean>;
  onLogout: () => void;
}

function getStoredToken(): string | null {
  const token = get();
  if (!token) {
    return null;
  }
  const res = jwtDdecode<JWT>(token);
  if (res.exp * 1000 > Date.now()) {
    return token;
  }
  remove();
  return null;
}

export const AuthContext = React.createContext<AuthContextValue>({
  token: getStoredToken(),
  onLogin: async (user: string, pass: string): Promise<boolean> => false,
  onLogout: (): void => {}
});

interface Props {
  children: ReactNode;
}

interface JWT {
  exp: number;
  sub: string;
}

export const AuthProvider = (props: Props): ReactElement => {
  const { children } = props;
  const [token, setToken] = React.useState<string | null>(getStoredToken());

  const handleLogin = async (user: string, pass: string): Promise<boolean> => {
    const token = await getToken(user, pass);
    if (token !== null) {
      set(token);
    }
    setToken(token);
    return token !== null;
  };

  const handleLogout = (): void => {
    remove();
    setToken(null);
  };

  const value: AuthContextValue = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
