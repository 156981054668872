import resolve from '../resolver';
import authorizedFetch from '../authorizedFetch';
import { MapData } from '@services/mapData';

const WCU_ID_PARAM_NAME = 'wcu_ids';

interface CheckTasksResponse {
  [id: string]: string;
}

interface WCUMap {
  association_time: string;
  map_info: MapData;
}

export const checkTasks = async (ids: number[]): Promise<CheckTasksResponse> => {
  const urlParams = new URLSearchParams();
  ids.forEach((id) => urlParams.append(WCU_ID_PARAM_NAME, String(id)));
  const res = await authorizedFetch(resolve('platform-maps/check-tasks', urlParams));
  return await res.json();
};

export const getWCUMaps = async (id: number): Promise<WCUMap[]> => {
  const res = await authorizedFetch(resolve(`platform-maps/${id}`));
  return await res.json();
};

export const create = async (
  wcuIds: number[],
  mapIds: number[],
  force: boolean = false
): Promise<void> => {
  const request = {
    wcu_ids: wcuIds,
    map_ids: mapIds,
    create_commands: true,
    commands_force: force
  };

  const res = await authorizedFetch(resolve('platform-maps/'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });
  return await res.json();
};
