import React, { ReactElement, useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EnhancedTableHead, { HeadCell } from './Head';
import EnhancedTableToolbar from './Toolbar';
import EnhancedTableAction, { TableAction } from './Action';
import { Checkbox, Typography } from '@mui/material';
import { Order, getComparator, stableSort } from '@helpers/filtering';
import CircularProgress from '@mui/material/CircularProgress';

interface EnhancedTableProps<T> {
  headCells: Array<HeadCell<T>>;
  data: T[];
  keyField: keyof T;
  title: string;
  onSearch?: (value: string) => void;
  actions?: Array<TableAction<T>>;
  selectableRow?: boolean;
  multiselect?: boolean;
  onCheckedChange?: (checked: T[]) => void;
  loading?: boolean;
  toolbarActions?: ReactElement[];
  toolbarFilters?: ReactElement[];
  message?: string;
}

export default function EnhancedTable<T>(props: EnhancedTableProps<T>): ReactElement {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<HeadCell<T>>(props.headCells[0]);
  const [selected, setSelected] = React.useState<T | null>(null);
  const [checked, setChecked] = React.useState<T[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    setSelected(null);
    setPage(0);
  }, [props.data]);

  const handleRequestSort = (head: HeadCell<T>): void => {
    const isAsc = orderBy.id === head.id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(head);
  };

  const handleClick = (value: T): void => {
    if (props.selectableRow !== true) {
      return;
    }
    if (selected === null || selected?.[props.keyField] !== value[props.keyField]) {
      return setSelected(value);
    }

    setSelected(null);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (obj: T): boolean =>
    !!selected && selected[props.keyField] === obj[props.keyField];

  const isChecked = (obj: T): boolean =>
    checked.find((c) => c[props.keyField] === obj[props.keyField]) !== undefined;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort<T>(
        props.data,
        getComparator(order, (item: T) => {
          return getCellValue(item, orderBy);
        })
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [props.data, order, orderBy, page, rowsPerPage]
  );

  const hasActions = props.actions !== undefined;

  const handleSelectAllClick = (checked: boolean): void => {
    if (checked) {
      const newSelected = new Array<T>().concat(props.data);
      return updatedChecked(newSelected);
    }
    updatedChecked([]);
  };

  const updatedChecked = (items: T[]): void => {
    setChecked(items);
    if (props.onCheckedChange) {
      props.onCheckedChange(items);
    }
  };

  const handleCheck = (isChecked: boolean, item: T): void => {
    if (!isChecked) {
      const newChecked = checked.filter((c) => c[props.keyField] !== item[props.keyField]);
      return updatedChecked(newChecked);
    }
    const newChecked = checked.concat([item]);
    updatedChecked(newChecked);
  };

  function getCellValue(item: T, headCell: HeadCell<T>): any {
    if (headCell.getValue) {
      return headCell.getValue(item);
    }
    if (headCell.field) {
      return item[headCell.field];
    }
    return item;
  }

  function getCellPresentation(item: T, headCell: HeadCell<T>): string | ReactElement {
    if (headCell.getPresentation) {
      return headCell.getPresentation(item);
    }
    if (headCell.getValue) {
      return String(headCell.getValue(item));
    }
    if (headCell.field) {
      return String(item[headCell.field]);
    }
    return String(item);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          actions={props.toolbarActions}
          filters={props.toolbarFilters}
          title={props.title}
          onSearch={props.onSearch}
          numSelected={checked.length}></EnhancedTableToolbar>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              headCells={props.headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              hasActions={hasActions}
              rowCount={props.data.length}
              onSelectAllClick={handleSelectAllClick}
              numChecked={checked.length}
              multiselect={props.multiselect}
            />
            <TableBody>
              {props.loading && (
                <TableRow>
                  <TableCell colSpan={props.headCells.length + 3}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!!props.message?.length && (
                <TableRow>
                  <TableCell colSpan={props.headCells.length + 3}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography>{props.message}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {!props.loading &&
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const isItemChecked = isChecked(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={String(row[props.keyField])}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}>
                      {props.multiselect && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemChecked}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              handleCheck(event.target.checked, row);
                            }}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell component="th" id={labelId} scope="row" align="left">
                        {getCellPresentation(row, props.headCells[0])}
                      </TableCell>
                      {props.headCells.slice(1).map((headCell, index) => {
                        return (
                          <TableCell key={index} align="left">
                            {getCellPresentation(row, headCell)}
                          </TableCell>
                        );
                      })}
                      {props.actions !== undefined && (
                        <TableCell key={index} align="left">
                          {props.actions.map((action) => {
                            return (
                              <EnhancedTableAction
                                action={action.action}
                                title={action.title}
                                icon={action.icon}
                                object={row}
                                key={action.title}></EnhancedTableAction>
                            );
                          })}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
