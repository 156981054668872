import { createGlobalStyle } from 'styled-components';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'Roboto';
}
`;

export default FontStyles;
