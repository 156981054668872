import React from 'react';
import { useTheme } from '@hooks/useTheme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider, THEME_ID, createTheme } from '@mui/material/styles';
import FontStyles from './FontStyles';
import Router from './router';
import { AppContainer } from './styles';

const materialTheme = createTheme();
const App: React.FC = () => {
  const theme = useTheme();

  return (
    <StyledThemeProvider theme={theme}>
      <FontStyles />
      <ThemeProvider theme={{ [THEME_ID]: materialTheme }}>
        <AppContainer>
          <Router />
        </AppContainer>
      </ThemeProvider>
    </StyledThemeProvider>
  );
};

export default App;
