import React, { ReactElement, useEffect, useState } from 'react';
import { PageContainer, TableContainer } from './styles';
import Table from '@components/Table';
import { HeadCell } from '@components/Table/Head';
import { TableAction } from '@components/Table/Action';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { WCU, list, isOnline, isUpdated } from '@services/wcu';
import WCUPropsDialog from './WCUPropsDialog';
import useApi from '@hooks/useApi';
import Online from '@components/Online';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import UpdateVersionsDialog from '@pages/Main/UpdateVersionsDialog';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from 'react-router-dom';
import { SEA_MAP } from '@constants/pages';
import { IDS } from '@constants/queryStringParams';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import AssociateMapsDialog from '@pages/Main/AssociateMapsDialog';
import AssociateTidesDialog from '@pages/Main/AssociateTidesDialog';

const headCells: Array<HeadCell<WCU>> = [
  {
    id: 'wcu_id',
    field: 'wcu_id',
    numeric: true,
    label: 'WCU ID'
  },
  {
    id: 'online',
    numeric: false,
    label: 'Online',
    getValue: isOnline,
    getPresentation: (wcu: WCU): ReactElement => {
      return <Online isOnline={isOnline(wcu)} />;
    }
  },
  {
    id: 'is_updated',
    numeric: false,
    label: 'Up to date',
    getValue: isOnline,
    getPresentation: (wcu: WCU): ReactElement => {
      return isUpdated(wcu) ? (
        <Tooltip title="Updated">
          <DoneAllIcon style={{ fill: '#00FF00' }} />
        </Tooltip>
      ) : (
        <Tooltip title="Outdated">
          <RemoveDoneIcon style={{ fill: '#FF0000' }} />
        </Tooltip>
      );
    }
  },
  {
    id: 'boat_name',
    field: 'boat_name',
    numeric: false,
    label: 'Boat Name'
  },
  {
    id: 'country',
    numeric: false,
    label: 'Country',
    getValue: (wcu: WCU): string => {
      return wcu?.heartbeat?.country ?? '';
    }
  },
  {
    id: 'versions.argus_repo',
    numeric: false,
    label: 'Argus',
    getValue: (wcu: WCU): string => {
      return wcu?.versions?.argus_repo ?? '';
    }
  },
  {
    id: 'versions.web_ui',
    numeric: false,
    label: 'Web UI',
    getValue: (wcu: WCU): string => {
      return wcu?.versions?.web_ui ?? '';
    }
  },
  {
    id: 'versions.carmenta_deployment',
    numeric: false,
    label: 'Carmenta',
    getValue: (wcu: WCU): string => {
      return wcu?.versions?.carmenta_deployment ?? '';
    }
  },
  {
    id: 'versions.windows_app',
    numeric: false,
    label: 'Windows App',
    getValue: (wcu: WCU): string => {
      return wcu?.versions?.windows_app ?? '';
    }
  },
  {
    id: 'versions.marinas_db',
    numeric: false,
    label: 'Marinas DB',
    getValue: (wcu: WCU): string => {
      return wcu?.versions?.marinas_db ?? '';
    }
  }
];

function filterWCU(wcus: WCU[], boatName: string, group: string): WCU[] {
  return wcus.filter((wcu): boolean => {
    if (boatName !== '' && !wcu.boat_name?.toLowerCase().includes(boatName)) {
      return false;
    }
    return !(group !== '' && wcu.group !== group);
  });
}

const Main: React.FC = () => {
  const [tableData, setTableData] = useState<WCU[]>([]);
  const [openWCUDialog, setOpenWCUDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openAssociateDialog, setOpenAssociateDialog] = useState(false);
  const [openAssociateTidesDialog, setOpenAssociateTidesDialog] = useState(false);
  const [selectedWCU, setSelectedWCU] = useState<WCU | null>(null);
  const [checkedWCU, setCheckedWCU] = useState<WCU[]>([]);
  const [groupFilter, setGroupFilter] = useState<string>('');
  const [boatNameFilter, setBoatNameFilter] = useState<string>('');
  const [tableFilters, setTableFilters] = useState<ReactElement[]>([]);
  const navigate = useNavigate();
  const getWCUs = useApi(list);

  useEffect(() => {
    void getWCUs.request();
  }, []);

  useEffect(() => {
    if (getWCUs.data === null) {
      return;
    }
    const groupsMap = new Map<string, boolean>();
    for (const wcu of getWCUs.data) {
      groupsMap.set(wcu.group, true);
    }
    const groups = Array.from(groupsMap.keys());

    const select = (
      <FormControl key="groups-filter" sx={{ width: '300px' }} size="small">
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
          id="group-select-label"
          label="Group"
          defaultValue={''}
          onChange={(event: SelectChangeEvent) => {
            setGroupFilter(event.target.value);
          }}>
          <MenuItem value={''}>
            <em>All</em>
          </MenuItem>
          {groups.map((group) => (
            <MenuItem key={group} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
    setTableFilters([select]);
  }, [getWCUs.data]);

  useEffect(() => {
    if (getWCUs.data === null) {
      return;
    }
    setTableData(filterWCU(getWCUs.data, boatNameFilter, groupFilter));
  }, [getWCUs.data, groupFilter, boatNameFilter]);

  const onSearch = (value: string): void => {
    setBoatNameFilter(value);
  };

  const actions: Array<TableAction<WCU>> = [
    {
      title: 'Show',
      action: (wcu: WCU) => {
        setSelectedWCU(wcu);
        setOpenWCUDialog(true);
      },
      icon: <VisibilityIcon style={{ fill: '#256BBB' }} />
    }
  ];

  const onCheckedChange = (wcus: WCU[]): void => {
    setCheckedWCU(wcus);
  };

  const openMap = (): void => {
    const ids = checkedWCU.map((wcu) => wcu.wcu_id).join('.');
    const searchParams = new URLSearchParams();
    searchParams.append(IDS, ids);
    navigate({
      pathname: SEA_MAP,
      search: '?' + searchParams.toString()
    });
  };

  const toolbarActions = [
    <Button
      onClick={() => {
        setOpenUpdateDialog(true);
      }}
      variant="contained"
      key={1}>
      Update&nbsp;Versions
    </Button>,
    <Button
      onClick={() => {
        setOpenAssociateDialog(true);
      }}
      variant="contained"
      key={2}>
      Associate&nbsp;Maps
    </Button>,
    <Button
      onClick={() => {
        setOpenAssociateTidesDialog(true);
      }}
      variant="contained"
      key={3}>
      Associate&nbsp;Tides
    </Button>,
    <Button onClick={openMap} variant="contained" key={4} startIcon={<PublicIcon />}>
      Show&nbsp;on&nbsp;Map
    </Button>
  ];

  return (
    <PageContainer>
      <TableContainer>
        <Table
          toolbarActions={toolbarActions}
          toolbarFilters={tableFilters}
          title="WCUs"
          data={tableData}
          headCells={headCells}
          keyField={'wcu_id'}
          onSearch={onSearch}
          actions={actions}
          multiselect={true}
          onCheckedChange={onCheckedChange}
          loading={getWCUs.loading}
          message={getWCUs.error}
        />
      </TableContainer>
      <WCUPropsDialog
        open={openWCUDialog}
        wcu={selectedWCU}
        onCloseRequest={() => {
          setOpenWCUDialog(false);
        }}
      />
      <UpdateVersionsDialog
        open={openUpdateDialog}
        wcu={checkedWCU}
        onCloseRequest={() => {
          setOpenUpdateDialog(false);
        }}
      />
      {openAssociateDialog && (
        <AssociateMapsDialog
          open={true}
          wcu={checkedWCU}
          onCloseRequest={() => {
            setOpenAssociateDialog(false);
          }}
        />
      )}
      {openAssociateTidesDialog && (
        <AssociateTidesDialog
          open={true}
          wcu={checkedWCU}
          onCloseRequest={() => {
            setOpenAssociateTidesDialog(false);
          }}
        />
      )}
    </PageContainer>
  );
};

export default Main;
