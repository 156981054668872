import resolve from '../resolver';
import authorizedFetch from '../authorizedFetch';

export interface TideData {
  vendor: string;
  region: string;
  tide_id: number;
  last_upload_time: string;
}

export interface TideFilters {
  descending?: boolean;
  sort_by?: keyof TideData;
}

const DEFAULT_TIDE_FILTERS: TideFilters = {
  descending: true,
  sort_by: 'tide_id'
};

function filtersToUrlParams(filters: TideFilters): URLSearchParams {
  const urlParams = new URLSearchParams();
  if (filters.descending) {
    urlParams.set('descending', filters.descending.toString());
  }
  if (filters.sort_by) {
    urlParams.set('sort_by', filters.sort_by);
  }
  return urlParams;
}

export const list = async (filters: TideFilters = DEFAULT_TIDE_FILTERS): Promise<TideData[]> => {
  const urlParams = filtersToUrlParams(filters);
  const res = await authorizedFetch(resolve('tides/', urlParams));
  return await res.json();
};
