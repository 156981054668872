import resolve from '../resolver';
import authorizedFetch from '../authorizedFetch';
import { idToName } from '@helpers/naming';
import productsMap from '@services/product/productsMap';

export interface Product {
  id: string;
  name: string;
  versions?: string[];
}

interface ProductsResponse {
  products: string[];
}

interface VersionsResponse {
  product: string;
  versions: string[];
}

export const list = async (): Promise<Product[]> => {
  try {
    const res = await authorizedFetch(resolve('ota/products'));
    const json: ProductsResponse = await res.json();
    return json.products.map((id: string) => ({
      id,
      name: productsMap[id] ?? idToName(id)
    }));
  } catch (e: unknown) {
    return [];
  }
};

export const getVersionsByProduct = async (productId: string): Promise<string[]> => {
  try {
    const res = await authorizedFetch(resolve('ota/product-versions/' + productId));
    const json: VersionsResponse = await res.json();
    return json.versions;
  } catch (e: unknown) {
    return [];
  }
};

export const getProductsWithVersions = async (): Promise<Product[]> => {
  try {
    const products = await list();
    const promises: Array<Promise<string[]>> = products.map(
      async (p: Product) => await getVersionsByProduct(p.id)
    );
    const responses = await Promise.all(promises);
    for (let i = 0; i < responses.length; i++) {
      products[i].versions = responses[i];
    }
    return products;
  } catch (e: unknown) {
    return [];
  }
};
