import styled from 'styled-components';

export const ActionsContainer = styled.div`
  padding: 20px;
`;

export const LoaderContainer = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
